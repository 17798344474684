<template>
  <div class="mt-5">
    <br /> 
    <br />
    <br />
    <div class="row">
      <div class="col-12 mb-2">
        <button @click="refresh()" class="btn ms-auto btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </div>
    </div>

    <!-- sales -->
    <div class="row align-items-stretch text-secondary">
      <div class="col-12 col-xl-3  p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-2">
            <i
              class="bi bi-cart3 text-primary"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-10">
            <div class="text-center ">
              Total Paiement en espèces
            </div>
            <div class="text-center">
              <span v-if="totalTodays.checkout" class="text-primary fw-bold">
                {{ totalTodays.checkout.totalCashPaid | formatWithSpaces }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Chèques clients impayés -->
      <div class="col-12 col-xl-3  p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-2">
            <i
              class="bi bi-window-x text-warning"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-10">
            <div class="text-center">
              Chèques clients impayés
            </div>
            <div class="text-center">
              <span v-if="totalTodays.checks" class="text-warning fw-bold">
                {{ totalTodays.checks.totalUnpaidCustomerChecks | formatWithSpaces  }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Chèques fournisseurs impayés -->
      <div class="col-12 col-xl-3  p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-2">
            <i
              class="bi bi-window-dash text-danger"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-10">
            <div class="text-center">
              Chèques fournisseurs impayés
            </div>
            <div class="text-center">
              <span v-if="totalTodays.checks" class="text-danger fw-bold">
                {{ totalTodays.checks.totalUnpaidSupplierChecks | formatWithSpaces }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Règlement Ventes Impayé -->
      <div class="col-12 col-xl-3  p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-2">
            <i
              class="bi bi-cash-stack text-success"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-10">
            <div class="text-center">
              Montant à la caisse
            </div>
            <div class="text-center">
              <span v-if="totalTodays.checkout" class="text-success fw-bold">
                {{ totalTodays.checkout.amountAtCheckout | formatWithSpaces }}
              </span>
            </div>
          </div>
        </div>
      </div>
    
    </div>
 
    <div class="row">
      <div class="col-xl-6 col-12 p-1">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col" class="text-center" colspan="4">
                  Top 10 clients avec crédits impayés
                </th>
              </tr>
              <tr>
                <th scope="col"></th>
                <th scope="col">Nom Complet</th>
                <th scope="col" class="text-center">Total impayé</th>
                <th scope="col" class="text-center d-xl-flex d-none">État de crédit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(credit, index) in top10Credits" :key="index++">
                <td>
                  {{ index }}
                </td>
                
                <td>
                  <span v-if="credit.customer">
                    {{ credit.customer.fullName }}
                  </span>
                </td>
                <td class="text-end">
                  <span class="text-danger fw-bold">
                    
                      {{
                        parseFloat(credit.totalUnpaid).toFixed(2) | formatWithSpaces
                      }}
                    
                  </span>
                </td>
                <td class="d-xl-flex d-none">
                  <span v-if="credit.customer">
                    <span v-if="credit.customer.maxUnpaid > 0">
                      <input
                        type="range"
                        :min="0"
                        :max="credit.customer.maxUnpaid"
                        :step="1"
                        :value="credit.totalUnpaid "
                        :disabled="true"
                        :class="{
                          'bg-danger': true,
                          'border': credit.totalUnpaid >= credit.customer.maxUnpaid,
                          'custom-disabled': true
                        }"
                      />
                    </span>
                  </span>

                </td>
                
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td class="text-end">
                  <span class="text-end bg-danger rounded px-2 py-1 text-white">
                    {{ parseFloat(top10TotalUnpaidSum).toFixed(2) | formatWithSpaces }}
                  </span>
                </td>
                <td class="d-xl-flex d-none"></td>
              </tr>
            </tfoot>
          </table>
      </div>
      <div class="col-xl-6 col-12">

      </div>
    </div>
 
     <div class="row">
        <div class="col-xl-3 align-items-stretch text-secondary p-2 "
        v-for="(section, index) in sections" :key="index">
          <div class="shadow rounded p-3 h-100">
              <div class="row" v-for="(item, itemIndex) in section.items" :key="itemIndex">
                  <div class="col">
                    {{ item.label }}
                  </div>
                  <div class=" col-auto">
                    <span v-if="totalTodays[section.key]" :class="item.color">
                      {{ totalTodays[section.key][item.value] | formatWithSpaces }}
                    </span>
                  </div>
              </div>
            </div>
        </div>
    </div> 
    <br />
    <br />
    <div class="row align-items-stratch">
      <!-- <div class="col-12 col-xl-4 p-2" v-if="barTodaysChartData">
        <div class="rounded bg-white border">
          <BarChartSalesTodayVue
            :chart-data="barTodaysChartData"
          ></BarChartSalesTodayVue>
        </div> 
      </div>-->

     

      <!-- <div class="col-12 col-xl-6">
        <line-chart
          :chart-data="lineChartData"
          :options="lineChartOptions"
        ></line-chart>
      </div> -->

      <!-- <div class="col-4">
        <scatter-chart
          :chart-data="scatterData"
          :options="scatterOptions"
        ></scatter-chart>
      </div> -->
    </div>
    <br />
    <br />

    <div class="row p-1">
      <div class="bg-white rounded border p-3">
        <div class="row">
          <div class="col text-center text-secondary">
            {{ $t("dashboard14") }}
          </div>
        </div>
        <br />
        <div
          v-for="(quantity, index) in quantityAnalytics"
          :key="index++"
          class="row"
        >
          <div class="col">{{ quantityreference }} {{ quantity.fullName }}</div>
          <div class="col-2">
            <span class="text-success">
              + {{ quantity.purchasedQuantity }}
            </span>
          </div>
          <div class="col-2">
            <span class="text-danger"> - {{ quantity.soldQuantity }} </span>
          </div>
          <div class="col-2">
            <span class="text-primary">
              {{ quantity.stockQuantity }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-xl-8 col-12"></div>
      <!-- <div class="col-xl-4 col-12">
        <div
          v-for="(user, index) in users"
          :key="index++"
          class="row d-flex border rounded align-items-center p-0 my-1"
        >
          <div class="col-auto m-0 p-0">
            <i
              class="bi bi-person-exclamation"
              :style="{
                fontSize: '27px !important',
              }"
            ></i>
          </div>
          <div class="col-6 m-0 p-0">
            <span v-if="user.employee">
              {{ user.employee.fullName }}
            </span>
          </div>
          <div class="col m-0 p-0">
            <span
              class="p-0 m-0"
              :style="{
                fontSize: '40px !important',
                fontWeight: 'bolder !important',
              }"
              :class="
                user.last_seen_minutes_ago < 30 &&
                user.last_seen_seconds_ago != -1
                  ? 'text-success'
                  : 'text-secondary'
              "
            >
              .
            </span>
            <span
              v-if="user.last_seen_seconds_ago == -1"
              class="text-secondary"
            >
              Inactif
            </span>
            <span
              v-else-if="user.last_seen_seconds_ago == 0"
              class="text-success"
            >
              Actif
            </span>
            <span
              v-else-if="user.last_seen_seconds_ago < 60"
              class="text-warning"
            >
              - {{ user.last_seen_seconds_ago }} secondes
            </span>
            <span
              v-else-if="user.last_seen_minutes_ago < 60"
              :class="
                user.last_seen_minutes_ago < 30 ? 'text-success' : 'text-waning'
              "
            >
              - {{ user.last_seen_minutes_ago }} minutes
            </span>
            <span v-else-if="user.last_seen_hours_ago < 24" class="text-danger">
              -{{ user.last_seen_hours_ago }} heures
            </span>
            <span v-else class="text-danger">
              - {{ user.last_seen_days_ago }} jours
            </span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BarChartVue from "../components/chart/BarChart.vue";
import BarChartSalesTodayVue from "../components/chart/BarChartSalesToday.vue";
import LineChart from "../components/chart/LineChart.vue";
import ScatterChart from "../components/chart/ScatterChart.vue";
import moment from "moment";
export default {
  components: {
    BarChartVue,
    ScatterChart,
    LineChart,
    BarChartSalesTodayVue,
  },
  data() {
    return {
      sections: [
        {
          key: 'sales',
          items: [
            { label: 'Total Ventes Aujourd\'hui', icon: 'bi bi-cart3', value: 'priceTotalTTCSale', color: 'text-primary' },
            { label: 'Total Ventes Payé', icon: 'bi bi-window-x', value: 'totalPaidSale', color: 'text-success' },
            { label: 'Total Ventes Impayé', icon: 'bi bi-window-dash', value: 'totalUnpaidSale', color: 'text-danger' },
            { label: 'Règlement Ventes Impayé', icon: 'bi bi-cash-stack', value: 'totalPaidOldBillSale', color: 'text-warning' },
            { label: 'Ventes Payé Espèces', icon: 'bi bi-cash', value: 'totalCashSale', color: 'text-success' },
            { label: 'Ventes Payé Virements', icon: 'bi bi-bank', value: 'totalBankTransferSale', color: 'text-danger' },
            { label: 'Ventes Payé Chèques', icon: 'bi bi-cash-coin', value: 'totalCheckSale', color: 'text-warning' },
          ]
        },
        {
          key: 'purchases',
          items: [
            { label: 'Total Achats d\'aujourd\'hui', icon: 'bi bi-cart3', value: 'priceTotalTTCPurchase', color: 'text-primary' },
            { label: 'Total Achats Payé', icon: 'bi bi-window-x', value: 'totalPaidPurchase', color: 'text-success' },
            { label: 'Total Achats Impayé', icon: 'bi bi-window-dash', value: 'totalUnpaidPurchase', color: 'text-danger' },
            { label: 'Règlement Achats Impayé', icon: 'bi bi-cash-stack', value: 'totalPaidOldBillPurchase', color: 'text-warning' },
            { label: 'Achats Payé Espèces', icon: 'bi bi-cash', value: 'totalCashPurchase', color: 'text-success' },
            { label: 'Achats Payé Virements', icon: 'bi bi-bank', value: 'totalBankTransferPurchase', color: 'text-danger' },
            { label: 'Achats Payé Chèques', icon: 'bi bi-cash-coin', value: 'totalCheckPurchase', color: 'text-warning' },
          ]
        },
        {
          key: 'expenses',
          items: [
            { label: 'Total Dépenses Aujourd\'hui', icon: 'bi bi-cart3', value: 'priceTotalTTCExpense', color: 'text-primary' },
            { label: 'Dépenses Payé Espèces', icon: 'bi bi-cash', value: 'totalCashExpense', color: 'text-success' },
            { label: 'Dépenses Payé Virements', icon: 'bi bi-bank', value: 'totalBankTransferExpense', color: 'text-danger' },
            { label: 'Dépenses Payé Chèques', icon: 'bi bi-cash-coin', value: 'totalCheckExpense', color: 'text-warning' },
          ]
        },
        {
          key: 'transactions',
          items: [
            { label: 'Total Transactions Aujourd\'hui', icon: 'bi bi-receipt', value: 'priceTotalTTCTransaction', color: 'text-primary' },
            { label: 'Transactions Payé Espèces', icon: 'bi bi-cash', value: 'totalCashTransaction', color: 'text-success' },
            { label: 'Transactions Payé Virements', icon: 'bi bi-bank', value: 'totalBankTransferTransaction', color: 'text-danger' },
            { label: 'Transactions Payé Chèques', icon: 'bi bi-cash-coin', value: 'totalCheckTransaction', color: 'text-warning' },
          ]
        },
      ],
    };
  },
  computed: {
    ...mapGetters("statistic", {
      totalTodays: "getTotalTodays",
      // barTodaysChartData: "getBarTodaysChartData",
      quantityAnalytics: "getQuantityAnalytics",
    }),

    ...mapGetters("user", {
      users: "getAll",
      userOnline: "getUserOnline",
    }),
    ...mapGetters("credit", {
      credits: "getCredits",
      
    }),
    top10Credits() {
      if (!this.credits) return [];
      var sortedCredits = this.credits.sort((a, b) => b.totalUnpaid - a.totalUnpaid);
      return sortedCredits.slice(0, 10);
    },
    top10TotalUnpaidSum() {
      if (!this.top10Credits) return 0;
      return this.top10Credits.reduce((acc, credit) => acc + credit.totalUnpaid, 0);
    },
    
  },
  mounted() {
    this.$store.dispatch("statistic/fetchTotalTodays");
    this.$store.dispatch(
      "statistic/fetchQuantityAnalytics",
      moment(new Date()).format("YYYY-MM-DD")
    );
    this.$store.dispatch("user/getAll");
    this.$store.dispatch("user/fetchUserStatus");
    this.$store.dispatch("credit/getAll");
  },
  methods: {
    refresh() {
      this.$store.dispatch("statistic/fetchTotalTodays");
      this.$store.dispatch("credit/getAll");
    },
  },
  filters: {
    formatWithSpaces(value) {
      // Ensure the value is a number and fix it to 2 decimal places
      const fixedValue = Number(value).toFixed(2);
      // Convert the fixed number to a string and apply spaces as thousands separators
      return fixedValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
 
};
</script>
